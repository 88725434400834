import axios, { AxiosInstance } from 'axios'
import getEnv from '@/plugins/getEnv'
import store from '@/store'

export default class AiService {
  private svc = axios.create({
    baseURL: 'https://ai-service.hub.codeless.biz/api/',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  private token = ''

  constructor(public environmentId: string) {
    const apiUrl = new URL(getEnv('VUE_APP_API_ENDPOINT'))

    this.svc.interceptors.request.use(
      (config) => {
        if (this.token) {
          config.headers.Authorization = `JWT ${this.environmentId} ${this.token}`
        } else {
          const sessionToken = JSON.stringify({
            d: apiUrl.host,
            s: store.getters['auth/session'],
            t: Date.now()
          })
          config.headers.Authorization = `Codeless ${this.environmentId} ${btoa(
            sessionToken
          )}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  async fetchToken() {
    if (this.token) return
    const response = await this.svc.get('token')
    this.token = response.data.token
  }

  async completions(request: any) {
    await this.fetchToken()
    // console.log('[AIService] /completions', { request }, request.prompt)
    const response = await this.svc.post('completions', request)
    // console.log('[AIService] /completions', { response: response.data })
    return response.data
  }

  async edits(request: any) {
    await this.fetchToken()
    // console.log('[AIService] /edits', { request })
    const response = await this.svc.post('edits', request, request.instruction)
    // console.log('[AIService] /edits', { response: response.data })
    return response.data
  }

  async vue(request: any) {
    await this.fetchToken()
    // console.log('[AIService] /vue', { request })
    const { data: r } = await this.svc.post('vue', request)
    console.log('[AIService] /vue', { response: r })
    const AsyncFunction = Object.getPrototypeOf(
      async function () {}
    ).constructor
    const fn = new AsyncFunction('request', r.script)
    const props = await fn(request)
    return {
      ...props,
      name: 'AiLayout',
      render: new Function(r.render),
      staticRenderFns: r.staticRenderFns.map((fn: string) => new Function(fn))
    }
  }
}
