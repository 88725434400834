import { Collection, FieldTypeDefinition } from '@/models'
import AIService from '@/services/AIService'
import { XMLBuilder, XMLParser } from 'fast-xml-parser'
import { alert } from '@/components/dialogs'
import _camelCase from 'lodash/camelCase'
import gql from 'graphql-tag'
import getClient from '@/plugins/vue-apollo/client'
import stripComments from 'strip-comments'

const xmlBuilder = new XMLBuilder({
  format: true
})

const xmlParser = new XMLParser({})

export default class CollectionsAIHelper {
  private aiService: AIService

  constructor(environmentId: string) {
    this.aiService = new AIService(environmentId)
  }

  async suggestNewField(
    collection: Partial<Collection>,
    fieldTypes: FieldTypeDefinition[]
  ) {
    if (!collection.fields || collection.fields.length < 2) {
      return null
    }
    const fieldTypesToRemove = [
      'oneOf',
      'manyOf',
      'range',
      'document',
      'singleSelect',
      'multipleSelect'
    ]

    const availableFieldTypes = fieldTypes
      .map((t) => t._id)
      .filter((f) => !fieldTypesToRemove.includes(f))

    const header = `<!--
      Collection name: ${collection.name}
      Available field types: ${availableFieldTypes.join(', ')}\n-->\n`

    const input = xmlBuilder
      .build({
        fields: {
          field: collection.fields.map((f) => {
            const field = { ...f }
            // @ts-ignore
            delete field.__typename
            return field
          })
        }
      })
      .slice(0, -10)
    try {
      const response = await this.aiService.completions({
        model: 'code-davinci-002',
        prompt: header + input,
        temperature: 0.5,
        max_tokens: 1024,
        presence_penalty: 0.5,
        stop: '</field>',
        suffix: '</field>'
      })

      const firstChoice = response.choices[0]

      if (firstChoice) {
        const xml = firstChoice.text
        const json = xmlParser.parse(xml)
        const newField = json.field
        newField.options = {}
        newField.name = _camelCase(newField.name)
        if (newField) {
          return newField
        }
      }
    } catch (error) {
      alert(
        'Se produjo un error en el servicio de IA, vuelve a intentarlo luego'
      )
    }

    return null
  }

  async generateDummyData(collectionId: string, schema: string) {
    const client = getClient()
    const response = await this.aiService.completions({
      model: 'text-davinci-003',
      prompt: `Usando la siguiente estructura de datos:\n\n${schema}\n\nGenera 5 registros dummy en formato JSON:\n[`,
      temperature: 0.5,
      max_tokens: 2048,
      stop: ']'
    })
    const firstChoice = response.choices[0]
    if (firstChoice) {
      const json = firstChoice.text
      const result = JSON.parse(`[${json}]`)
      for (const r of result) {
        delete r._id
        delete r.createdAt
        await client.mutate({
          mutation: gql`
            mutation addItem($collectionId: ID, $data: JSON) {
              item: addItem(collectionId: $collectionId, data: $data)
            }
          `,
          variables: {
            collectionId: collectionId,
            data: r
          }
        })
      }
      return
    }
    throw new Error('No se pudieron generar los datos de prueba')
  }
}
