import gql from 'graphql-tag'

export const CollectionCreateFragment = gql`
  fragment CollectionCreate on Collection {
    _id
    name
    type
    environmentId
    createdAt
  }
`

export const CollectionFieldsFragment = gql`
  fragment CollectionFields on Collection {
    fields {
      name
      type
      label
      options
      optional
    }
  }
`

export const CollectionIndexesFragment = gql`
  fragment CollectionIndexes on Collection {
    indexes {
      fields
      type
      unique
    }
  }
`

export const CollectionFragment = gql`
  fragment Collection on Collection {
    ...CollectionCreate
    ...CollectionFields
    ...CollectionIndexes
  }
  ${CollectionCreateFragment}
  ${CollectionFieldsFragment}
  ${CollectionIndexesFragment}
`
