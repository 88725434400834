







































































































































































































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import _mapValues from 'lodash/mapValues'
import draggable from 'vuedraggable'
import { CollectionFieldsFragment } from '@/components/componentTypes/collections/fragments'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'
import {
  Collection,
  PaginatedQueryResult,
  VectorMetadata,
  Chunker
} from '@/models'
import { ApolloQueryResult } from 'apollo-client'
import Fields from '@/components/form/Fields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import _startCase from 'lodash/startCase'
import numeral from '@/plugins/numeral'
import { alert, confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    Loading,
    Fields,
    CollectionWatcher
  },
  apollo: {
    collection: {
      query: gql`
        query getCollection($collectionId: ID) {
          collection: collection(collectionId: $collectionId) {
            _id
            name
          }
        }
        ${CollectionFieldsFragment}
      `,
      variables() {
        return {
          collectionId: this.collectionId
        }
      }
    },
    chunkers: gql`
      query {
        chunkers {
          _id
          name
          icon
          optionsParams
        }
      }
    `,
    vectors: {
      query: gql`
        query getVectors($page: BigInt, $limit: BigInt, $collectionId: ID) {
          vectors: documentsFromCollection(
            page: $page
            limit: $limit
            collectionId: $collectionId
          ) {
            _id
            totalCount
            totalPages
            hasNextPage
            hasPreviousPage
            items
          }
        }
      `,
      variables() {
        return {
          page: this.pagination.page,
          limit: this.pagination.itemsPerPage,
          collectionId: this.collectionId
        }
      },
      result(r: ApolloQueryResult<PaginatedQueryResult<VectorMetadata>>) {
        if (r.data?.items) {
          return r.data
        } else {
          return {
            _id: '',
            items: [],
            totalCount: 0,
            totalPages: 0,
            hasNextPage: false,
            hasPreviousPage: false
          }
        }
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only'
    }
  }
})
export default class CollectionEditFields extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) collectionId!: string

  collection: Partial<Collection> | null = null

  chunkers: Chunker[] = []
  addDialogChunkerType: Chunker | null = null
  addOptions: Record<string, any> = {}
  addOptionsValid: boolean = false
  addLoading: boolean = false

  getChunker(chunkerId: string) {
    const chunker = this.chunkers.find((c) => c._id === chunkerId)
    return (
      chunker || {
        _id: '',
        name: '',
        icon: '',
        optionsParams: {}
      }
    )
  }

  get addDialogOpen() {
    return !!this.addDialogChunkerType
  }

  set addDialogOpen(v: boolean) {
    if (!v) {
      this.addDialogChunkerType = null
    }
  }

  @Watch('addDialogChunkerType')
  onAddDialogChunkerTypeChange() {
    if (!this.addDialogChunkerType) {
      this.addOptions = {}
      this.addOptionsValid = false
    }
  }

  async addVectors() {
    if (!this.collection || !this.addDialogChunkerType) return
    this.addLoading = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($collectionId: ID, $chunkerId: ID, $chunkerOptions: JSON) {
            addVectors(
              collectionId: $collectionId
              chunkerId: $chunkerId
              chunkerOptions: $chunkerOptions
            )
          }
        `,
        // Parameters
        variables: {
          collectionId: this.collection._id,
          chunkerId: this.addDialogChunkerType._id,
          chunkerOptions: this.addOptions
        }
      })
      console.log(result)
      this.addDialogOpen = false
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.addLoading = false
    }
  }

  vectors: PaginatedQueryResult<VectorMetadata> | null = null

  staticPagination = {
    page: 1,
    itemsPerPage: 25,
    sortBy: ['createdAt'],
    sortDesc: [true],
    multiSort: false
  }

  get pagination() {
    return {
      ...this.staticPagination,
      totalItems: this.vectors?.totalCount || 0
    }
  }

  get totalPages() {
    return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
  }

  get totalItems() {
    return numeral(this.pagination.totalItems).format('0,000')
  }

  get pageRules() {
    return [
      (v: number) =>
        (!!v && v >= 1 && v <= this.totalPages) || 'Página inválida'
    ]
  }

  set pagination(v: any) {
    this.staticPagination = v
  }

  async update() {
    return this.$apollo.queries.vectors.refetch()
  }

  editVector(v: VectorMetadata) {
    alert('Esta funcionalidad no está implementada aún (Visualizar Vectores)')
  }

  async deleteVector(v: VectorMetadata) {
    const confirmation = await confirmDelete(
      '¿Estás seguro de eliminar este vector de la base de conocimiento?'
    )
    if (!confirmation) return
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($collectionId: ID, $vectorId: ID) {
            deleteVectors(collectionId: $collectionId, vectorId: $vectorId)
          }
        `,
        // Parameters
        variables: {
          collectionId: this.collectionId,
          vectorId: v._id
        }
      })
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  searchInput = ''
  searchResults: any[] | null = null
  searchRunning = false

  async performSearch() {
    if (!this.searchInput) return
    this.searchRunning = true
    try {
      const result = await this.$apollo.query({
        query: gql`
          query ($collectionId: ID, $query: String, $nResults: Float) {
            queryVectors(
              collectionId: $collectionId
              query: $query
              nResults: $nResults
            )
          }
        `,
        fetchPolicy: 'network-only',
        // Parameters
        variables: {
          collectionId: this.collectionId,
          query: this.searchInput,
          nResults: 10
        }
      })
      this.searchResults = result.data.queryVectors
      console.log(result)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.searchRunning = false
    }
  }

  async exitSearch() {
    this.searchInput = ''
    this.searchResults = null
    this.searchRunning = false
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
