
















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import anime from 'animejs'
import numeral from '@/plugins/numeral'

@Component({})
export default class AnimatedNumber extends Vue {
  @Prop({ type: Number }) value?: number
  @Prop({ type: String, default: '0,0.[00]' }) format!: string
  @Prop({ type: String, default: 'easeInOutQuad' }) easing!: string
  @Prop({ type: Number, default: 300 }) duration!: number

  displayValue = 0

  mounted() {
    this.animateValue(this.value)
  }

  get formattedValue() {
    if (this.value === undefined) return '-'
    return numeral(this.displayValue).format(this.format)
  }

  @Watch('value')
  animateValue(value?: number) {
    anime({
      targets: this,
      displayValue: value,
      duration: this.duration,
      easing: this.easing
    })
  }
}
