











































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Collection } from '@/models'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import { CollectionCreateFragment } from '@/components/componentTypes/collections/fragments'
import EditFields from '@/components/componentTypes/collections/EditFields.vue'
import EditVector from '@/components/componentTypes/collections/EditVector.vue'
import EditAdvanced from '@/components/componentTypes/collections/EditAdvanced.vue'
import EditAdvancedVector from '@/components/componentTypes/collections/EditAdvancedVector.vue'
import EditIndexes from '@/components/componentTypes/collections/EditIndexes.vue'
import EditBrowse from '@/components/componentTypes/collections/EditBrowse.vue'

@Component({
  components: {
    EditBrowse,
    EditIndexes,
    EditAdvanced,
    EditAdvancedVector,
    Loading,
    EditFields,
    EditVector
  },
  apollo: {
    collection: {
      query: gql`
        query getCollection($collectionId: ID) {
          collection(collectionId: $collectionId) {
            ...CollectionCreate
          }
        }
        ${CollectionCreateFragment}
      `,
      variables() {
        return {
          collectionId: this.componentId
        }
      }
    }
  }
})
export default class CollectionEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) componentId!: string
  @Prop({ type: String, default: 'component' }) currentTab!: string

  collection: Partial<Collection> | null = null

  @Watch('collection')
  updateName(collection: Collection) {
    this.$emit('name', collection.name)
    if (collection.type === 'collection' || collection.type === null) {
      this.$emit('additionalTabs', {
        component: {
          name: this.$t('collections_edit.mounted.component'),
          icon: 'short_text'
        },
        indexes: {
          name: this.$t('collections_edit.mounted.indexes'),
          icon: 'list'
        },
        browse: {
          name: this.$t('collections_edit.mounted.browse'),
          icon: 'edit_note'
        },
        advanced: {
          name: this.$t('collections_edit.mounted.advanced'),
          icon: 'settings'
        }
      })
    }
    if (collection.type === 'vector') {
      this.$emit('additionalTabs', {
        component: 'Base de Conocimiento',
        advanced: 'Avanzado'
      })
      if (['browse', 'indexes'].includes(this.currentTab)) {
        this.currentTab = 'component'
      }
    }
  }
}
