
















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import XLSX from 'xlsx'
import gql from 'graphql-tag'
import { prompt } from '@/components/dialogs'
import getEnv from '@/plugins/getEnv'
import AIHelper from './AIHelper'

@Component({})
export default class CollectionEditAdvanced extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) collectionId!: string

  working = false
  aiHelper?: AIHelper

  @Watch('aiEnabled', { immediate: true })
  onAiEnabledChange() {
    if (this.aiEnabled) {
      this.aiHelper = new AIHelper(this.environmentId)
    } else {
      this.aiHelper = undefined
    }
  }

  get aiEnabled() {
    return this.$store.state.experiments.active['collection-editor-ai']
  }

  async generateSchema(format: string) {
    const { data } = await this.$apollo.mutate({
      mutation: gql`
        mutation downloadSchema($collectionId: ID, $format: String) {
          schema: downloadSchema(collectionId: $collectionId, format: $format)
        }
      `,
      variables: {
        collectionId: this.collectionId,
        format,
      },
    })
    return data.schema
  }

  async downloadJsonSchema() {
    if (this.working) return
    try {
      this.working = true
      const schema = await this.generateSchema('json')
      let link = document.createElement('a')
      link.download = `${this.collectionId}.schema.json`
      link.href = URL.createObjectURL(
        new Blob([JSON.stringify(schema)], { type: 'application/json' })
      )
      link.click()
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: this.$t('collections_editAdv.script.downloadSchema.error'),
      })
    } finally {
      this.working = false
    }
  }

  async downloadTsSchema() {
    if (this.working) return
    try {
      this.working = true
      const schema = await this.generateSchema('ts')
      let link = document.createElement('a')
      link.download = `${this.collectionId}.ts`
      link.href = URL.createObjectURL(
        new Blob([schema], { type: 'application/x-typescript' })
      )
      link.click()
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: this.$t('collections_editAdv.script.downloadSchema.error'),
      })
    } finally {
      this.working = false
    }
  }

  async exportHeaders() {
    if (this.working) return
    try {
      this.working = true
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation exportHeaders($collectionId: ID) {
            headers: exportHeaders(collectionId: $collectionId)
          }
        `,
        variables: {
          collectionId: this.collectionId,
        },
      })
      const { headers } = data
      XLSX.writeFile(headers, `${this.collectionId}.xlsx`)
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: this.$t('collections_editAdv.script.exportHeaders.error'),
      })
    } finally {
      this.working = false
    }
  }

  async clearData() {
    if (this.working) return
    const confirm = (await prompt(
      this.$t('collections_editAdv.script.clearData.prompt[0]').toString() +
        this.collectionId +
        this.$t('collections_editAdv.script.clearData.prompt[1]').toString(),
      {
        okButtonText: this.$t(
          'collections_editAdv.script.clearData.delete'
        ) as string,
        okButtonIcon: 'delete',
        okButtonColor: 'error',
      }
    )) as string
    if (!confirm || confirm?.toLowerCase() !== 'eliminar datos') {
      return this.$store.dispatch('snackbar/showSnackbar', {
        text: this.$t('collections_editAdv.script.clearData.cancel'),
      })
    }
    try {
      this.working = true
      await this.$apollo.mutate({
        mutation: gql`
          mutation removeDataCollection($collectionId: ID) {
            removeDataCollection(collectionId: $collectionId)
          }
        `,
        variables: {
          collectionId: this.collectionId,
        },
      })
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: this.$t('collections_editAdv.script.clearData.empty'),
      })
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: this.$t('collections_editAdv.script.clearData.emptyError'),
      })
    } finally {
      this.working = false
    }
  }

  async deleteCollection() {
    if (this.working) return
    const confirm = (await prompt(
      this.$t(
        'collections_editAdv.script.deleteCollection.prompt[0]'
      ).toString() +
        this.collectionId +
        this.$t(
          'collections_editAdv.script.deleteCollection.prompt[1]'
        ).toString() +
        this.collectionId +
        this.$t(
          'collections_editAdv.script.deleteCollection.prompt[2]'
        ).toString(),
      {
        okButtonText: this.$t(
          'collections_editAdv.script.deleteCollection.delete'
        ) as string,
        okButtonIcon: 'delete',
        okButtonColor: 'error',
      }
    )) as string
    if (!confirm || confirm?.toLowerCase() !== this.collectionId) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        text: this.$t('collections_editAdv.script.deleteCollection.cancel'),
      })
    }
    try {
      this.working = true
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation removeCollection($collectionId: ID) {
            removeCollection(collectionId: $collectionId)
          }
        `,
        variables: {
          collectionId: this.collectionId,
        },
      })
      this.$emit('delete', data.removeCollection)
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: this.$t('collections_editAdv.script.deleteCollection.emptyError'),
      })
    } finally {
      this.working = false
    }
  }

  async generateDummyData() {
    if (this.working || !this.aiHelper) return
    try {
      this.working = true
      const schema = await this.generateSchema('ts')
      await this.aiHelper.generateDummyData(this.collectionId, schema)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se generaron los registros de prueba.',
      })
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar generar los registros.',
      })
    } finally {
      this.working = false
    }
  }

  get adminLink() {
    if (window.mobileApp) return ''
    if (
      getEnv('VUE_APP_ADMIN_DOMAIN_V1') &&
      this.environmentId &&
      this.collectionId
    ) {
      return `${document.location.protocol}//${getEnv(
        'VUE_APP_ADMIN_DOMAIN_V1'
      )}/${this.environmentId}/collections/${this.collectionId}`
    }
    return ''
  }
}
