






























































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { CollectionFieldsFragment } from './fragments'
import MonacoEditor from '@/plugins/monaco'
import {
  Collection,
  CollectionData,
  FilterWithSchema,
  PaginatedQueryResult,
  Table,
  TableFieldType
} from '@/models'
import Loading from '@/components/Loading.vue'
import ViewItems from '@/components/componentTypes/tables/ViewItems.vue'
import Field from '@/components/fields/Field.vue'
import FilterForm from '@/components/form/FilterForm.vue'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'
import { ApolloQueryResult } from 'apollo-client'
import EditAddUpdateItem from '@/components/componentTypes/collections/EditAddUpdateItem.vue'

interface FilterValue {
  filterId?: string
  filterOptions: Record<string, any>
}

@Component({
  components: {
    EditAddUpdateItem,
    Loading,
    Field,
    FilterForm,
    ViewItems,
    MonacoEditor,
    CollectionWatcher
  },
  apollo: {
    collection: {
      query: gql`
        query getCollection($collectionId: ID) {
          collection(collectionId: $collectionId) {
            _id
            ...CollectionFields
          }
        }
        ${CollectionFieldsFragment}
      `,
      variables() {
        return {
          collectionId: this.collectionId
        }
      }
    },
    results: {
      query: gql`
        query getCollectionData(
          $page: BigInt
          $limit: BigInt
          $collectionId: ID
          $filterId: ID
          $filterOptions: JSON
          $sortBy: String
          $sortType: String
          $idSearch: String
        ) {
          results: documentsFromCollection(
            page: $page
            limit: $limit
            collectionId: $collectionId
            filterId: $filterId
            filterOptions: $filterOptions
            sortBy: $sortBy
            sortType: $sortType
            idSearch: $idSearch
          ) {
            _id
            totalCount
            totalPages
            hasNextPage
            hasPreviousPage
            items
          }
        }
      `,
      variables() {
        return {
          page: this.pagination.page,
          limit: this.pagination.itemsPerPage,
          collectionId: this.collectionId,
          filterId: this.filter.filterId,
          filterOptions: this.filter.filterOptions,
          sortBy: this.pagination.sortBy[0],
          sortType: this.pagination.sortDesc[0] ? 'DESC' : 'ASC',
          idSearch: this.idSearch
        }
      },
      result(r: ApolloQueryResult<PaginatedQueryResult<CollectionData>>) {
        this.hasQueryFilter = false
        if (r.data?.items) {
          return r.data
        } else {
          return {
            _id: '',
            items: [],
            totalCount: 0,
            totalPages: 0,
            hasNextPage: false,
            hasPreviousPage: false
          }
        }
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only'
    },
    currentFilter: {
      query: gql`
        query currentFilter($filterId: ID) {
          currentFilter: filter(filterId: $filterId) {
            _id
            title
            schema: serializedSchema(includeParameters: true)
            formSchema: serializedSchema(includeParameters: false)
          }
        }
      `,
      variables() {
        return {
          filterId: this.filter.filterId
        }
      },
      skip() {
        return !this.filter?.filterId
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only'
    }
  }
})
export default class CollectionEditBrowse extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) collectionId!: string

  collection: Collection | null = null
  results: PaginatedQueryResult<CollectionData> | null = null
  currentFilter: FilterWithSchema | null = null
  selection: any[] = []
  working = false
  addUpdateDialogOpen = false
  addUpdateMode = 'create'
  idSearch = ''
  queryResult = null
  filter: FilterValue = {
    filterId: '',
    filterOptions: {}
  }
  showEditorFilter = false
  queryFilter: String = ''
  hasQueryFilter = false
  staticPagination = {
    page: 1,
    itemsPerPage: 25,
    sortBy: ['createdAt'],
    sortDesc: [true],
    multiSort: false
  }

  get pagination() {
    return {
      ...this.staticPagination,
      totalItems: this.results?.totalCount || 0
    }
  }
  get primaryColor() {
    return (this.$vuetify.theme.currentTheme.primary as any).base
  }

  set pagination(v: any) {
    this.staticPagination = v
  }

  get table(): Table {
    const fields = this.collection?.fields || []
    return {
      _id: this.collectionId,
      environmentId: this.environmentId,
      title: '',
      name: '',
      createdAt: new Date(),
      collectionId: this.collectionId,
      collection: this.collection!,
      allowsNoFilter: true,
      filterByDefault: '',
      orderByAsc: false,
      filtersIds: [],
      filters: [],
      fields: [
        {
          type: TableFieldType.MultipleSelect,
          fieldName: '_select',
          label: '',
          options: {}
        },
        {
          type: TableFieldType.Field,
          fieldName: '_id',
          sortable: false,
          label: 'ID',
          options: {}
        },
        {
          type: TableFieldType.Field,
          fieldName: 'createdAt',
          sortable: true,
          label: this.$t(
            'collections_editBrowse.script.table.createdAt'
          ) as string,
          options: {}
        },
        ...fields.map((f) => ({
          type: TableFieldType.Field,
          fieldName: f.name,
          sortable: true,
          label: f.label,
          options: {}
        }))
      ],
      orderFiltersByName: false,
      footer: [],
      exportable: false,
      exportWithId: false,
      defaultLimit: 50,
      roles: [],
      sortable: false,
      disableRefresh: false
    }
  }

  async update() {
    return this.$apollo.queries.results.refetch()
  }

  updateSelection(selection: any[]) {
    this.selection = selection.map((s) => s.id)
  }

  async deleteSelected() {
    if (
      this.working ||
      !confirm(
        `${this.$t('collections_editBrowse.script.deleteSelected')} (${
          this.selection.length
        })`
      )
    )
      return
    this.working = true
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation deleteSelected($collectionId: ID, $itemIds: [ID]) {
            removeItems(collectionId: $collectionId, itemIds: $itemIds)
          }
        `,
        variables: {
          collectionId: this.collectionId,
          itemIds: this.selection.map((i) => i.id)
        }
      })
      await this.$store.dispatch('snackbar/showSnackbar', {
        text:
          this.selection.length === 1
            ? this.$t('collections_editBrowse.script.deletedSelected.case1')
            : this.$t('collections_editBrowse.script.deletedSelected.case2')
      })
      this.selection = []
    } catch (e) {
      this.$emit('error', e)
    } finally {
      this.working = false
    }
  }

  async editSelected() {
    this.addUpdateDialogOpen = true
    this.addUpdateMode = 'edit'
  }

  async duplicateSelected() {
    this.addUpdateDialogOpen = true
    this.addUpdateMode = 'duplicate'
  }

  async addItem() {
    this.addUpdateDialogOpen = true
    this.addUpdateMode = 'create'
  }

  async handleQueryFilter() {
    const { data } = await this.$apollo.query({
      query: gql`
        query getCollectionData(
          $page: BigInt
          $limit: BigInt
          $collectionId: ID
          $filterId: ID
          $filterOptions: JSON
          $sortBy: String
          $sortType: String
          $idSearch: String
          $queryFilter: String
        ) {
          documentsFromCollection(
            page: $page
            limit: $limit
            collectionId: $collectionId
            filterId: $filterId
            filterOptions: $filterOptions
            sortBy: $sortBy
            sortType: $sortType
            idSearch: $idSearch
            queryFilter: $queryFilter
          ) {
            _id
            totalCount
            totalPages
            hasNextPage
            hasPreviousPage
            items
          }
        }
      `,
      variables: {
        page: this.pagination.page,
        limit: this.pagination.itemsPerPage,
        collectionId: this.collectionId,
        filterId: this.filter.filterId,
        filterOptions: this.filter.filterOptions,
        sortBy: this.pagination.sortBy[0],
        sortType: this.pagination.sortDesc[0] ? 'DESC' : 'ASC',
        idSearch: this.idSearch,
        queryFilter: this.queryFilter
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only'
    })
    if (data?.documentsFromCollection?.items) {
      this.results = data.documentsFromCollection
    } else {
      this.results = {
        _id: '',
        items: [],
        totalCount: 0,
        totalPages: 0,
        hasNextPage: false,
        hasPreviousPage: false
      }
    }
    this.hasQueryFilter = !!this.queryFilter
    this.showEditorFilter = false
  }
  handleFilterUpdate() {
    this.$apollo.queries.currentFilter.refetch()
  }
}
