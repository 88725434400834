var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BlockView',{attrs:{"environmentId":_vm.table.environmentId,"componentId":_vm.blockId,"itemDefinition":{
    id: _vm.blockId,
    type: 'block',
    sizeSmall: 12,
    sizeLarge: 12,
    blockId: _vm.blockId,
    namespace: 'blocks'
  },"preview":_vm.preview,"viewParams":_vm.itemParams}})}
var staticRenderFns = []

export { render, staticRenderFns }